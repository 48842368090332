
import { Options, Vue } from 'vue-class-component';
import { ElButton, ElForm, ElFormItem, ElInput, ElLink, ElCheckbox, ElRadioGroup, ElRadio, ElMessage, ElDialog, ElPopover } from 'element-plus';
import { validatePhone, validateEMail, validateIdNo, validateForm, validateName } from '@/utils/validate';
import { CapacityEnum } from '@/utils/constants';
import { enumToDescriptedArray } from '@/utils/enumToKv';
import { some, pick, omit } from 'lodash';
import { saveArUser, sendMsgCode, updateArUser } from '@/api/index';
import { useStore, Store } from 'vuex';
import { useRouter, Router } from 'vue-router';
import { IUserPersonalInfo } from '@/interface/userInfo';
import LoginDialog from './home/sigupDialog.vue';
import StudentInfo from './studentInfo.vue';
import JobInfo from './jobInfo.vue';
import OtherInfo from './otherInfo.vue';
import RaceAgreement from './raceAgreement.vue';
import RaceRule from './raceRules.vue';

declare global {
    interface Window {
        sscodeCallback: any;
    }
}
interface IRealInfo {
    CurrName: string;
    IdCard: string;
}
type Info = StudentInfo | JobInfo | OtherInfo;
@Options({
    props: {
        userStatus: {},
        realInfo: <IRealInfo>{},
    },
    components: {
        Button: ElButton,
        Input: ElInput,
        Form: ElForm,
        FormItem: ElFormItem,
        Link: ElLink,
        Checkbox: ElCheckbox,
        RadioGroup: ElRadioGroup,
        Dialog: ElDialog,
        Radio: ElRadio,
        Popover: ElPopover,
        StudentInfo,
        JobInfo,
        OtherInfo,
        LoginDialog,
        RaceAgreement,
        RaceRule,
    },
})
export default class UserInfo extends Vue {
    userStatus!: {};
    realInfo!: IRealInfo;
    private real: IRealInfo = this.realInfo;
    private store: Store<any> = useStore();
    private router: Router = useRouter();
    private capacityEnum = enumToDescriptedArray(CapacityEnum);
    private formValue = {
        Mobile: '',
        Token: '',
        Capacity: 1,
        IdNo: '',
        Email: '',
        PlatName: '',
        Agreement: false,
    };
    private childInfo = {};
    private timer: number | null = 0;
    private showLoginDialog = false;
    private showAgreementDialog = false;
    private showRuleDialog = false;
    private hasShowAgreementDialog = true;
    private hasShowRuleDialog = true;
    private formInfo: InstanceType<typeof ElForm> | null = null;
    private childForm: Info | null = null;
    private saveLoading = false;
    private rules: object = {
        Mobile: [
            {
                validator: (rule: object, value: string, callback: Function) => {
                    const { error } = validatePhone(rule, value);
                    if (error) {
                        this.codeCouldChecked = false;
                        callback(new Error(error));
                        return;
                    }
                    this.codeCouldChecked = true;
                    callback();
                },
                trigger: ['blur', 'change'],
            },
        ],
        Token: [
            {
                validator: (rule: object, value: string, callback: Function) => {
                    if (!value) {
                        return callback(new Error('请输入验证码'));
                    }
                    callback();
                },
                trigger: ['blur', 'change'],
            },
        ],
        Email: [
            {
                validator: (rule: object, value: string, callback: Function) => {
                    const { error } = validateEMail(rule, value);
                    if (error) {
                        callback(new Error(error));
                        return;
                    }
                    callback();
                },
                trigger: ['blur', 'change'],
            },
        ],
        IdNo: [
            {
                validator: (rule: object, value: string, callback: Function) => {
                    const { error } = validateIdNo(rule, value, this.real?.IdCard);
                    if (error) {
                        callback(new Error(error));
                        return;
                    }
                    callback();
                },
                trigger: ['blur', 'change'],
            },
        ],
        Agreement: [
            {
                validator: (rule: object, value: string, callback: Function) => {
                    if (!value) {
                        callback(new Error('请阅读并同意《参赛协议》《大赛规程》'));
                        return;
                    }
                    callback();
                },
                trigger: ['blur', 'change'],
            },
        ],
        PlatName: [
            {
                validator: (rule: object, value: string, callback: Function) => {
                    const { error } = validateName(rule, value, this.real?.CurrName);
                    if (error) {
                        callback(new Error(error));
                        return;
                    }
                    callback();
                },
                trigger: ['blur', 'change'],
            },
        ],
    };
    mounted(): void {
        this.formInfo = this.$refs?.userInfo as InstanceType<typeof ElForm>;
        this.childForm = this.$refs?.childForm as Info;
        this.$watch(
            'userStatus',
            (value: any) => {
                if (value?.AuditState === 2) {
                    this.$nextTick(() => {
                        this.setInitData();
                    });
                }
            },
            { immediate: true }
        );
        this.$watch('realInfo', (value: any) => {
            if (value) {
                this.real = value;
            }
        });
        this.addSscode();
    }
    private setInitData() {
        const userStatus = omit(this.userStatus, ['Mobile', 'IdNo']);
        this.formValue = { ...this.formValue, ...userStatus, Agreement: true };
        this.childInfo = pick(this.formValue, ['Major', 'School', 'Industry', 'Eb', 'Mentor', 'Company', 'Position']);
    }
    private addSscode() {
        window.sscodeCallback = async (res: any) => {
            const { Mobile } = this.formValue;
            // res（用户主动关闭验证码）= {ret: 2, ticket: null}
            // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
            if (res.ret === 0) {
                const { randstr, ticket } = res;
                const { Code, Data } = await sendMsgCode({
                    RandStr: randstr,
                    Ticket: ticket,
                    Mobile,
                });
                if (!Code) {
                    ElMessage.success({
                        message: '发送成功',
                        type: 'success',
                    });
                    this.sendCode();
                }
            }
        };
    }
    private sendCodeText: string | number = '获取验证码';

    private codeCouldChecked = false;

    get getDisabled(): Boolean {
        if (this.codeCouldChecked && Number.isNaN(Number(this.sendCodeText))) {
            return false;
        }
        return true;
    }
    private sendCode() {
        // 发送短信请求成功
        this.sendCodeText = 60;
        this.timer = window.setInterval(() => {
            this.sendCodeText = <number>this.sendCodeText - 1;
            if (this.sendCodeText === 1) {
                this.sendCodeText = '重新发送';
                clearInterval(<number>this.timer);
                this.timer = null;
            }
        }, 1000);
    }
    private async saveUserHttp() {
        const childrenData = (<Info>this.childForm).formValue;
        const data: IUserPersonalInfo = { ...this.formValue, ...childrenData };
        const method = data?.Id ? updateArUser : saveArUser;
        const { Code } = await method(omit(data, ['RejectReason', 'AuditState', 'Uin']));
        if (Code === 'UnauthorizedOperation') {
            this.showLoginDialog = true;
        }
        if (Code) return false;
        return true;
    }
    private async saveUser() {
        this.saveLoading = true;
        this.validateData();
        const validateRes = await this.validateData();
        if (some(validateRes, v => !v)) {
            this.saveLoading = false;
            return;
        }
        this.saveLoading = false;
        const res = await this.saveUserHttp();
        if (res) {
            this.store.dispatch('setUserStatus');
            this.router.push('./personalInfo');
        }
    }
    private async validateData() {
        const childrenValidate = (<Info>this.childForm).validateFormChildren();
        const formValidate = validateForm(<InstanceType<typeof ElForm>>this.formInfo);
        return Promise.all([childrenValidate, formValidate]);
    }
    private closeToIndex() {
        this.showLoginDialog = false;
        this.router.push('./');
    }
    private showRule() {
        this.showRuleDialog = true;
        this.hasShowRuleDialog = false;
    }
    private showAgreement() {
        this.showAgreementDialog = true;
        this.hasShowAgreementDialog = false;
    }
    beforeDestroy() {
        this.timer = null;
    }
    private clearChild() {
        this.childInfo = {};
    }
}
