
import { Options, Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { useRouter, Router } from 'vue-router';
import { logout } from '@/api/index';

@Options({
    props: {
        title: String,
        goPage: Function,
        sideTiTle: String,
        active: String,
        eventOeder: String,
    },
})
export default class GrayHeader extends Vue {
    eventOeder!: string;
    goPage!: Function;
    private store: Store<any> = useStore();
    public router: Router = useRouter();
    private personalRoute = '/personalCenter';
    private handleClick(path: string) {
        this.router.push(path);
    }
    private notShowName = ['IndexMobile', 'Home'];
    get activeName() {
        return this.$route.name;
    }
    get hasLogin(): Boolean {
        return this.store.state.loginStatus.haslogin;
    }
    get hasAudit(): Number {
        if (this.store.state.userStatus.AuditState) {
            this.personalRoute = '/personalInfo';
        }
        return this.store.state.userStatus.AuditState;
    }
    get raceInfo() {
        return this.store.state.raceInfo;
    }
    private handleLogin() {
        this.$emit('handle-login');
    }
    private async handleLogout() {
        const { Code } = await logout();
        if (Code) return;
        const url = encodeURIComponent(`https://console.cloud.tencent.com/taop/login?redirectUrl=${window.location.href}`);
        window.location.replace(`https://cloud.tencent.com/login/quit?s_url=${url}`);
    }
}
