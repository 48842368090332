
import { Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { IRaceInfo } from '@/interface/raceInfo';
import moment from 'moment';

export default class RaceFlow extends Vue {
    private store: Store<any> = useStore();
    get raceInfo(): IRaceInfo {
        return this.store.state.raceInfo;
    }
    get flowItemUp(): Array<any> {
        return [
            {
                id: 1,
                title: '初赛',
                subTitle: `${moment(this.raceInfo.PreStart).format('MM月DD日')}~${moment(this.raceInfo.PreEnd).format('MM月DD日')}`,
            },
            {
                id: 3,
                title: '决赛',
                subTitle: `${moment(this.raceInfo.FinalStart).format('MM月DD日')}`,
            },
        ];
    }
    get flowItemDown(): Array<any> {
        return [
            {
                id: 0,
                title: '报名&认证',
                subTitle: `${moment(this.raceInfo.RegStart).format('MM月DD日')}-${moment(this.raceInfo.RegEnd).format('MM月DD日')}`,
            },
            {
                id: 2,
                title: '复赛',
                subTitle: `${moment(this.raceInfo.SemiStart).format('MM月DD日')}-${moment(this.raceInfo.SemiEnd).format('MM月DD日')}`,
            },
        ];
    }
    get activeFlow(): Array<Number> {
        const res = [];
        const arrFlow = [
            this.raceInfo.RegStart,
            this.raceInfo.RegEnd,
            this.raceInfo.PreStart,
            this.raceInfo.PreEnd,
            this.raceInfo.SemiStart,
            this.raceInfo.SemiEnd,
            this.raceInfo.FinalStart,
            this.raceInfo.FinalEnd,
        ];
        // const temp = '2021-09-29T13:39:43+08:00';
        let pos = 1;
        for (let i = 0; i < 4; i++) {
            if (this.raceInfo.CurTime > arrFlow[pos]) {
                pos += 2;
                continue;
            } else if (this.raceInfo.CurTime >= arrFlow[pos - 1]) res.push(i);
            pos += 2;
        }
        return res;
    }

    clickFlow() {
        this.$router.push('/flowDetail');
    }
}
