import crypto from 'crypto';

export function makeMd5(str: string) {
    const res = crypto.createHash('md5').update(str).digest('hex');
    return res;
}

export function raceTimeLine() {
    return ['RegStart', 'CurTime', 'RegEnd', 'PreStart', 'PreEnd', 'SemiStart', 'SemiEnd', 'FinalStart', 'FinalEnd'];
}

interface ICookieObject {
    [key: string]: string;
}

export function getCookie(str: string, keyStr: string): string {
    if (!str) return '';
    const cookieArray: Array<string> = str.split(';');
    const cookieObj: ICookieObject = {};
    cookieArray.forEach((v: string) => {
        const [key, val] = v.split('=');
        cookieObj[key] = val;
    });
    return cookieObj[keyStr] || '';
}

export function searchToObject(str: string): { [key: string]: string } {
    if (!str) {
        return {};
    }
    const match = decodeURIComponent(str).match(/^\??(.*?)(#.*)?$/);
    if (!match) {
        return {};
    }
    const obj: any = {};
    for (const item of match[1].split('&')) {
        const [key, value] = item.split('=');
        obj[key] = value;
    }
    return obj;
}

export function pushWindow(path: any) {
    const a = document.createElement('a');
    a.setAttribute('href', path);
    a.setAttribute('id', '__2h3n8asd');
    a.setAttribute('target', '_blank');
    const existDom = document.getElementById('__2h3n8asd');
    if (!existDom) {
        document.body.appendChild(a);
    } else {
        existDom.setAttribute('href', path);
    }
    a.click();
}
