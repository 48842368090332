
import { Options, Vue } from 'vue-class-component';
import { rankTeamInfo } from '@/interface/homeInfo';
import { getQueryRank } from '@/api/index';
import moment from 'moment';
import { useStore, Store } from 'vuex';
import { IRaceInfo, channelInfo } from '@/interface/raceInfo';

@Options({
    props: {
        step: {
            type: Number,
            default: 1,
        },
        pageSize: {
            type: Number,
            default: 10,
        },
        showButton: {
            type: Boolean,
            default: true,
        },
    },
})
export default class SingleStageRank extends Vue {
    step!: number;
    pageSize!: number;
    private activeTrack = 0;
    private stageItem: Array<string> = ['初赛阶段排名', '复赛阶段排名', '决赛阶段排名'];
    // private trackTitle: Array<String> = ['赛道一', '赛道二', '赛道三', '赛道四', '赛道五'];
    private rankItem: Array<String> = ['队伍排名', '队伍名称', '排名变化', '最佳成绩', '最佳成绩提交时间'];
    private top3Image: Array<string> = [
        'https://static.wecity.qq.com/miying/01-6a3feabac17cd66ba57c1c12481aa9a8.png',
        'https://static.wecity.qq.com/miying/02-b50bd0a5591c1d12b3fe8191ebffe622.png',
        'https://static.wecity.qq.com/miying/03-712f1b31d500da1098c939c07d31dcfc.png',
    ];
    private currentRank: Array<Array<String>> = [];
    private stage = this.step;
    private store: Store<any> = useStore();
    get trackTitle(): Array<string> {
        return this.store.state.channelInfo.map((item: channelInfo) => item.CharName);
    }
    get channelInfo(): Array<channelInfo> {
        return this.store.state.channelInfo;
    }
    get raceInfo(): IRaceInfo {
        return this.store.state.raceInfo;
    }
    get isReady(): Boolean {
        return this.raceInfo?.Id !== 0 && this.channelInfo[0]?.Id !== undefined;
    }
    async getStageRank(curPage: number, pageSize: number, channelId: string, stage: string) {
        // eslint-disable-next-line no-await-in-loop
        const res = await getQueryRank(curPage, pageSize, [
            { Field: 'ChannelId', opt: 0, Value: channelId },
            { Field: 'Step', opt: 0, Value: stage },
            { Field: 'RaceId', opt: 0, Value: this.raceInfo?.Id },
        ]);
        if (!res) return;
        const { Code, Data } = res;

        const stepRank = Data.map((team: any, index: number) => {
            let temp: string | number = '';
            temp = index < 3 ? this.top3Image[index] : team.Order;
            return [temp, team.TeamName, team.LastOrder - team.Order, team.Score, moment(team.RecordTime).format('YYYY-MM-DD')];
        });
        this.currentRank = stepRank;
    }

    async clickTrack(stage: number, index: number) {
        this.activeTrack = index;
        const channelId = this.channelInfo[index]?.Id;
        await this.getStageRank(1, this.pageSize, channelId, `${stage}`);
    }
    clickRankDetail(stage: number) {
        this.$router.push({ path: 'rankDetail', query: { step: `${stage}` } });
    }
    mounted() {
        this.$watch('isReady', async (val: any) => {
            if (val) {
                await this.getStageRank(1, this.pageSize, this.channelInfo[0]?.Id, `${this.stage}`);
            }
        });
        this.$watch('step', async (value: any) => {
            this.stage = value;
            await this.getStageRank(1, this.pageSize, this.channelInfo[0]?.Id, `${value}`);
        });
        if (this.$route.query.step) {
            this.stage = Number(this.$route.query.step);
        }
        if (this.raceInfo?.Id !== 0 && this.channelInfo[0]?.Id !== undefined) this.getStageRank(1, this.pageSize, this.channelInfo[0]?.Id, `${this.stage}`);
    }
}
