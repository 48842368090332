
import { Options, Vue } from 'vue-class-component';
import UserInfo from '@/components/userInfo.vue';
import { IRaceInfo } from '@/interface/raceInfo';
import Swiper from 'swiper';
import { useRouter, Router } from 'vue-router';
import { useStore, Store } from 'vuex';
import { logout } from '@/api/index';
import { searchToObject } from '@/utils/commom';
import { toPCDialog } from '@/utils/changeDevice';
import RaceChannel from '../components/home/raceChannel.vue';
import RaceFlow from '../components/home/raceFlow.vue';
import RacePrize from '../components/home/racePrize.vue';
import RaceJuries from '../components/home/raceJuries.vue';
import RaceRules from '../components/home/raceRules.vue';
import RaceRank from '../components/home/raceRank.vue';
import RaceFooter from '../components/home/raceFooter.vue';
import SigupDialog from '../components/home/sigupDialog.vue';
import EndSignupDialog from '../components/home/endSigupDialog.vue';

export interface MenuInfo {
    name: String;
    className: String;
}

@Options({
    components: {
        UserInfo,
        RaceChannel,
        RaceFlow,
        RacePrize,
        RaceJuries,
        RaceRules,
        RaceRank,
        RaceFooter,
        SigupDialog,
        EndSignupDialog,
    },
})
export default class Home extends Vue {
    private msg = '1';
    private router: Router = useRouter();
    private swiper: Swiper | null = null;
    private dialogShow: Boolean = false;
    private endDialogShow: Boolean = false;
    // private isLogin: Boolean = true;
    private fixMenu: Boolean = false;
    private store: Store<any> = useStore();
    // 菜单
    private menuItem: Array<MenuInfo> = [
        { name: '赛道信息', className: 'aigame-track' },
        { name: '比赛流程', className: 'aigame-flow' },
        { name: '奖项设置', className: 'aigame-prize' },
        { name: '评委介绍', className: 'aigame-introduce' },
        { name: '排行榜', className: 'aigame-rank' },
        { name: '赛制规则', className: 'aigame-rules' },
    ];
    private menuActive: Number = 0;

    get topRight(): String {
        return this.store.state.loginStatus.haslogin ? '个人中心' : '登录';
    }
    get hasLogin(): Boolean {
        return this.store.state.loginStatus.haslogin;
    }
    get hasAudit(): Number {
        return this.store.state.userStatus.AuditState;
    }
    get raceInfo(): IRaceInfo {
        return this.store.state.raceInfo;
    }

    private closeDialog(val: Boolean) {
        this.dialogShow = val;
    }
    private endCloseDialog(val: Boolean) {
        this.endDialogShow = val;
    }

    private loginToTaop() {
        // 已经报名
        if (this.hasLogin && this.hasAudit !== 0) {
            this.router.push('./personalInfo');
            return;
        }
        // 已经登录
        if (this.hasLogin) {
            this.router.push('./personalCenter');
            return;
        }
        this.dialogShow = true;
    }

    private toPerson() {
        // 没登录
        if (!this.hasLogin) {
            this.dialogShow = true;
            return;
        }
        // 已经报名
        if (this.hasAudit !== 0) {
            this.router.push('./personalInfo');
            return;
        }
        // const tem = '2021-09-12T21:19:27+08:00';
        // 已经登录没报名+在报名时间以内
        if (this.hasAudit === 0 && this.raceInfo?.CurTime <= this.raceInfo.RegEnd) {
            this.router.push('./personalCenter');
            return;
        }
        // 已经登录没报名+过了报名时间
        this.endDialogShow = true;
    }

    private onClick(className?: String) {
        let dom = document.querySelector(`.${className}`) as HTMLElement;
        let offsetTop = dom.offsetTop;
        // eslint-disable-next-line no-cond-assign
        while ((dom = dom.offsetParent as HTMLElement)) {
            offsetTop += dom.offsetTop;
        }
        window.scrollTo({
            top: offsetTop - 300,
            behavior: 'smooth',
        });
        // document.querySelector(`.${className}`)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    private onScroll() {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const bannerHeight = document.querySelector('.aigame-banner') as HTMLElement;
        this.fixMenu = scrollTop > bannerHeight.clientHeight;
        for (let i = this.menuItem.length - 1; i >= 0; i--) {
            let item = document.querySelector(`.${this.menuItem[i].className}`) as HTMLElement;
            let offsetTop = item.offsetTop;
            // eslint-disable-next-line no-cond-assign
            while ((item = item.offsetParent as HTMLElement)) {
                offsetTop += item.offsetTop;
            }
            if (scrollTop >= offsetTop - 300) {
                this.menuActive = i;
                break;
            }
        }
    }

    async mounted(): Promise<void> {
        toPCDialog();
        // 导航
        window.addEventListener('scroll', this.onScroll, false);
        const { name } = searchToObject(window.location.search);
        if (name) {
            this.onClick(name);
        }
    }
    private async handleLogout() {
        const { Code } = await logout();
        if (Code) return;
        window.location.replace(`https://cloud.tencent.com/login/quit?s_url=${encodeURIComponent('https://console.cloud.tencent.com/taop')}`);
    }
    beforeUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }
}
