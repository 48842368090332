// AuditState  队伍审核状态 1 待审核 2 拒绝 2 通过
export enum AuditStateEnum {
    '待审核' = 1,
    '拒绝' = 2,
    '通过' = 3,
}

// 队伍状态 1 活动中  2 已解散
export enum TeamStateEnum {
    '活动中' = 1,
    '已解散' = 2,
}

// 用户在队伍中状态 1 已参队  2 已退队  3 被退队
export enum UserStateEnum {
    '已参队' = 1,
    '已退队' = 2,
    '被退队' = 3,
}

// 用户身份
export enum CapacityEnum {
    '学生' = 1,
    '在职' = 2,
    '其它' = 3,
}
// 问题类型
export enum ArQaEnum {
    '个人问答' = 'ArQa',
    '论坛问答' = 'ComArQa',
}
// 用户教育背景
export enum EbEnum {
    '专科' = '1',
    '本科 ' = '2',
    '硕士研究生' = '3',
    '博士研究生' = '4',
}
