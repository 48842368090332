
import { Options, Vue } from 'vue-class-component';
import { ElForm, ElFormItem, ElInput } from 'element-plus';
import { EbEnum } from '@/utils/constants';
import { validateForm } from '@/utils/validate';

@Options({
    props: {
        childInfo: Object,
    },
    components: {
        Input: ElInput,
        Form: ElForm,
        FormItem: ElFormItem,
    },
})
export default class JobInfo extends Vue {
    childInfo!: Object;
    private ebEnum = EbEnum;
    public formValue = {
        Position: '',
        Company: '',
        Industry: '',
    };
    private formInfo: InstanceType<typeof ElForm> | null = null;
    private rules: object = {
        Company: [{ required: true, message: '请输入工作单位名称', trigger: ['blur', 'change'] }],
        Position: [{ required: true, message: '请输入职务', trigger: ['blur', 'change'] }],
        Industry: [{ required: true, message: '请输入行业所属领域', trigger: ['blur', 'change'] }],
    };
    public validateFormChildren() {
        return validateForm(<InstanceType<typeof ElForm>>this.formInfo);
    }
    mounted(): void {
        this.formInfo = this.$refs?.jobInfo as InstanceType<typeof ElForm>;
        this.$watch(
            'childInfo',
            (value: any) => {
                this.formValue = { ...this.formValue, ...value };
            },
            { immediate: true }
        );
    }
}
