
import { Vue } from 'vue-class-component';
import Swiper from 'swiper';
import { juryItem } from '@/utils/juriesInfo';

export default class RaceJuries extends Vue {
    // 评委
    private swiper: Swiper | null = null;
    private juryItem = juryItem;
    mounted() {
        this.swiper = new Swiper('.swiper-container', {
            // 循环
            loop: true,
            autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
            },
            slidesPerView: 4,
            spaceBetween: 20,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        this.swiper.el.onmouseover = () => {
            this.swiper?.autoplay?.stop();
        };

        this.swiper.el.onmouseout = () => {
            this.swiper?.autoplay?.start();
        };
    }
}
