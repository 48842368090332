
import { Options, Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import SigupDialog from '@/components/home/sigupDialog.vue';
import { useRouter, Router } from 'vue-router';
import GrayHeader from '@/components/grayHeader.vue';

@Options({
    components: {
        SigupDialog,
        GrayHeader,
    },
})
export default class Home extends Vue {
    private dialogShow: Boolean = false;
    public router: Router = useRouter();
    private closeDialog() {
        this.dialogShow = false;
    }
    private store: Store<any> = useStore();
    get hasLogin(): Boolean {
        return this.store.state.loginStatus.haslogin;
    }
    get hasAudit(): Number {
        return this.store.state.userStatus.AuditState;
    }
    async mounted(): Promise<void> {
        setTimeout(() => {
            this.login();
        }, 500);
    }
    private handleLogin() {
        this.dialogShow = true;
    }
    private login() {
        if (this.hasLogin) {
            this.handleNoInfo();
            return;
        }
        const { pathname } = window.location;
        if (['/personalInfo', '/personalCenter'].includes(pathname)) {
            this.dialogShow = true;
        }
    }
    private handleNoInfo() {
        if (this.hasAudit) return;
        const { pathname } = window.location;
        if (['/personalInfo'].includes(pathname)) {
            this.router.push('./');
        }
    }
    closeToIndex() {
        this.dialogShow = false;
        const { pathname } = window.location;
        if (['/personalInfo', '/personalCenter'].includes(pathname)) {
            this.router.push('./');
        }
    }
}
