import http from '@/utils/http';

// 查询赛道
export async function getQueryArChannel() {
    return http.request({
        url: '/aiRace/QueryArChannel',
        method: 'post',
        data: {
            Req: {},
        },
    });
}

// 竞赛信息
export async function getQueryArRace() {
    return http.request({
        url: `/aiRace/QueryArRace`,
        method: 'post',
        data: {
            Req: {},
        },
    });
}

// 论坛公共问题查询 opt 6 为 like
export async function getQueryComArQa(curPage: number, pageSize: number, conditions?: Array<Object>) {
    return http.request({
        url: `/aiRace/QueryComArQa`,
        method: 'post',
        data: {
            Req: {
                Page: {
                    CurPage: curPage,
                    PageSize: pageSize,
                },
                Conditions: conditions,
            },
        },
    });
}

// 论坛个人问题查询 opt 6 为 like
export async function getQueryArQa(curPage: number, pageSize: number, conditions?: Array<Object>) {
    return http.request({
        url: `/aiRace/QueryArQa`,
        method: 'post',
        data: {
            Req: {
                Page: {
                    CurPage: curPage,
                    PageSize: pageSize,
                },
                Conditions: conditions,
            },
        },
    });
}

// 发布论坛问题
export async function saveAqQa(data: object) {
    return http.request({
        url: `/aiRace/SaveArQa`,
        method: 'post',
        data: {
            Req: [data],
        },
    });
}

// 生成邀请码
export async function getInviteToken() {
    return http.request({
        url: `/aiRace/GetInviteToken`,
        method: 'post',
    });
}

// 竞赛报名
export async function saveArUser(data: object) {
    return http.request({
        url: `/aiRace/SaveArUser`,
        method: 'post',
        data: {
            Req: [data],
        },
    });
}
// 更新竞赛报名
export async function updateArUser(data: object) {
    return http.request({
        url: `/aiRace/UpdateArUser`,
        method: 'post',
        data: {
            Req: [data],
        },
    });
}
// 竞赛报名信息查询
export async function getArUser() {
    return http.request({
        url: `/aiRace/QueryArUser`,
        method: 'post',
        data: {
            Req: {},
        },
    });
}
// 队伍信息查询
export async function getArTeam(Conditions?: any) {
    return http.request({
        url: `/aiRace/QueryArTeam`,
        method: 'post',
        data: {
            Req: { Conditions },
        },
        params: { hiddenTip: true },
    });
}

// 队伍名称更新
export async function updateArTeam(data: object) {
    return http.request({
        url: `/aiRace/UpdateArTeam`,
        method: 'post',
        data: {
            Req: [{ ...data }],
        },
    });
}
// 队伍名称新建
export async function saveArTeam(data: object) {
    return http.request({
        url: `/aiRace/SaveArTeam`,
        method: 'post',
        data: {
            Req: [{ ...data }],
        },
    });
}

// 组队参队"TeamId": "队伍编号",
export async function saveArUserComb(data: object) {
    return http.request({
        url: `/aiRace/SaveArUserComb`,
        method: 'post',
        data: {
            Req: [{ ...data }],
        },
        params: { hiddenTip: true },
    });
}

// 组队成员查询
export async function queryTeamMember() {
    return http.request({
        url: `/aiRace/QueryTeamMember`,
        method: 'post',
        data: {
            Req: {},
        },
    });
}

interface ITeam {
    Id: string | undefined;
    TeamId?: string;
    UserState?: string;
}
// 组队组队更新人员信息 "Id": "自增Id", "TeamId": "队伍编号",UserState: 2  退队 3   移除队员
export async function updateArUserComb(data: ITeam) {
    return http.request({
        url: `/aiRace/UpdateArUserComb`,
        method: 'post',
        data: {
            Req: [{ ...data }],
        },
    });
}

// 组队解散队伍
export async function deleteArTeam({ TeamId }: ITeam) {
    return http.request({
        url: `/aiRace/DeleteArTeam`,
        method: 'post',
        data: {
            Req: {
                Conditions: [
                    {
                        Field: 'Id',
                        Opt: 0,
                        Value: TeamId,
                    },
                ],
            },
        },
    });
}
// 短信发送 SendMsgCode RandStr/Ticket/Mobole
export async function sendMsgCode(data: object) {
    return http.request({
        url: `/aiRace/SendMsgCode`,
        method: 'post',
        data: {
            Req: [{ ...data }],
        },
    });
}
// QueryArUserRealInfo 查询实名信息
export async function queryUserRealInfo() {
    return http.request({
        url: `/aiRace/QueryArUserRealInfo`,
        method: 'post',
        data: {
            Req: {},
        },
        params: { hiddenTip: true },
    });
}
// 退出登录
export async function logout() {
    return http.request({
        url: 'aiRace/Logout',
        method: 'post',
        data: {
            Req: {},
        },
    });
}
// 查询用户NoteBook提交记录
export async function queryArRecord(data: any) {
    return http.request({
        url: `/aiRace/QueryArRecord`,
        method: 'post',
        data: {
            Req: {
                ...data,
            },
        },
    });
}
// 查询用户还可提交记录次数
export async function queryCountArRecord(data: any) {
    return http.request({
        url: `/aiRace/CountArRecord`,
        method: 'post',
        data: {
            Req: {
                ...data,
            },
        },
    });
}
// 查询用户队伍当前最新排名
export async function queryCountArRank(conditions: Array<Object>) {
    return http.request({
        url: `/aiRace/QueryArRank`,
        method: 'post',
        data: {
            Req: {
                Conditions: conditions,
            },
        },
    });
}
// 查询排名信息
export async function getQueryRank(curPage: number, pageSize: number, conditions: Array<Object>) {
    return http.request({
        url: '/aiRace/QueryArRaceTop',
        method: 'post',
        data: {
            Req: {
                Page: {
                    CurPage: curPage,
                    PageSize: pageSize,
                },
                Conditions: conditions,
            },
        },
    });
}
// 提交作品 SubmitWorks
export async function submitWorks(data: any) {
    return http.request({
        url: `/aiRace/SubmitWorks`,
        method: 'post',
        data: {
            Req: {
                ...data,
            },
        },
    });
}
// 奖品地址
export async function updatePostInfo(data: any) {
    return http.request({
        url: '/aiRace/UpdatePostInfo',
        method: 'post',
        data: {
            Req: [
                {
                    ...data,
                },
            ],
        },
    });
}
// 查询用户反馈
export async function queryArFeedback() {
    return http.request({
        url: '/aiRace/QueryArFeedback',
        method: 'post',
        data: {
            Req: {
                Page: {
                    CurPage: 1,
                    PageSize: 5,
                },
            },
        },
    });
}
// 保存用户反馈
export async function saveArFeedback(data: any) {
    return http.request({
        url: '/aiRace/SaveArFeedback',
        method: 'post',
        data: {
            Req: [
                {
                    ...data,
                },
            ],
        },
    });
}
