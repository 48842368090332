import { Dialog } from 'vant';

export function isMobile() {
    const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
}

export function toPCDialog() {
    if (isMobile()) {
        Dialog.confirm({
            message: '当前使用手机访问电脑版首页，是否需要跳转打开手机版首页？',
        })
            .then(() => {
                window.location.replace(`https://${window.location.hostname}/mobile/index.html`);
            })
            .catch(() => {
                // to do
            });
    }
}

export function toMobileDialog() {
    if (!isMobile()) {
        Dialog.confirm({ message: '当前使用电脑访问手机版首页，是否需要跳转打开电脑版首页？' })
            .then(() => {
                window.location.replace(`https://${window.location.hostname}`);
            })
            .catch(() => {
                // todo
            });
    }
}
