import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { ElNotification } from 'element-plus';
import { Toast } from 'vant';
import store from '@/store';
import { makeMd5 } from './commom';

class Http {
    private pathName = window.location.pathname;
    private pattern = /mobile/i;
    private isMobile = this.pattern.test(this.pathName);
    public async request(options: AxiosRequestConfig) {
        const instance = axios.create();
        const timestamp = new Date().valueOf();
        let hiddenTip = false;
        if (options.params) {
            if (options.params.hiddenTip) {
                hiddenTip = true;
            }
            delete options.params;
        }
        const requestConfig = { ...options };
        requestConfig.headers = {
            Accept: 'application/json',
            timestamp,
            signature: makeMd5(`17tmiNW2GkyM7akD67EPS&!aeM9${timestamp}`),
            ...requestConfig.headers,
        };
        this.interceptors(instance, hiddenTip);
        try {
            const res = await instance(options);
            return this.responseSuccess(res);
        } catch (error) {
            return this.responseError(error);
        }
    }

    private interceptors(instance: AxiosInstance, hiddenTip: boolean) {
        // 响应拦截
        instance.interceptors.response.use(
            response => {
                const { status, statusText } = response;
                if (status < 200 || status >= 300) {
                    this.isMobile
                        ? Toast.fail(statusText)
                        : ElNotification({
                              message: statusText,
                              type: 'error',
                          });
                    return Promise.reject(response);
                }
                if (response) {
                    const { data } = response;
                    const { Response } = data || {};
                    const { Error: errorRsp } = Response || {};
                    if (errorRsp) {
                        const { Code, Message } = errorRsp;
                        // code为未登陆或登陆失效时，清空登陆信息
                        if (Code === 'UnauthorizedOperation') {
                            store.dispatch('setLogout');
                            return Promise.reject(errorRsp);
                        }
                        if (Code) {
                            !hiddenTip &&
                                (this.isMobile
                                    ? Toast.fail(Message)
                                    : ElNotification({
                                          message: Message,
                                          type: 'error',
                                      }));
                            return Promise.reject(errorRsp);
                        }
                    }
                    return Promise.resolve(Response);
                }
                return Promise.reject(response);
            },
            error => {
                this.isMobile
                    ? Toast.fail('网络异常，请稍后再试')
                    : ElNotification({
                          message: '网络异常，请稍后再试',
                          type: 'error',
                      });
                console.error(error);
                // store.dispatch('setLogout');
                // 登陆失败信息清除
            }
        );
    }

    private responseSuccess(res: any) {
        return res;
    }

    private responseError(e: Error) {
        return e;
    }
}
const http = new Http();
export default http;
