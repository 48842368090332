
import { Options, Vue } from 'vue-class-component';
import { rankTeamInfo } from '@/interface/homeInfo';
import { getQueryRank } from '@/api/index';
import SingleStageRank from '@/components/home/singleStageRank.vue';

@Options({
    components: {
        SingleStageRank,
    },
})
export default class RaceRank extends Vue {}
