import Swiper from 'swiper';

export const juryItem = [
    [
        {
            title: '王宁利',
            subTitle: '首都医科大学附属北京同仁医院 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/王宁利-57e4b87dca15634397e648999095ec40.png',
            mask: '教授（主任医师），全国政协委员，北京同仁眼科中心主任，全国防盲技术指导组组长，中国医学科学院学部委员，国家眼科诊断与治疗设备工程技术研究中心主任，首都医科大学眼科学院院长',
        },
    ],
    [
        {
            title: '李坤成',
            subTitle: '磁共振成像和脑信息学北京市重点实验室 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/李坤成-ebc837b855a5ccc0c7de49795fb5dfaa.png',
            mask: '主任医师/二级教授，首都医科大学宣武医院首席专家，磁共振成像和脑信息学北京市重点实验室主任，《中国医学影像技术》杂志主编，阿尔兹海默病防治协会副会长兼影像分会主委',
        },
    ],
    [
        {
            title: '唐晓英',
            subTitle: '北京理工大学生命学院 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/唐晓英-637f169b412a771cd23ea68e7a288c7f.png',
            mask: '北京理工大学教授，博士生导师。主要从事生物医学工程及通信与信息系统领域的科研和教学工作，在多模跨尺度生物医学信号检测与处理、健康状态物理调控方法与设备、脑机工程关键技术等方面有很好的工作和技术转化的经验',
        },
    ],
    [
        {
            title: '程敬亮',
            subTitle: '郑州大学第一附属医院  教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/5d6de6b406042b808307c208faeffca0-5d6de6b406042b808307c208faeffca0.png',
            mask: '郑州大学第一附属医院磁共振科主任、医学影像中心主任、医技医学部主任，二级教授、主任医师、博士生导师，郑州大学特聘教授。已发表科研论文200余篇，主编和参编影像学专著23部。',
        },
    ],
    [
        {
            title: '傅小龙',
            subTitle: '上海市胸科医院 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/傅小龙-b91eaa41579e4ce3a03868ab41e134db.png',
            mask: '主任医师、博士生导师，上海交通大学附属胸科医院放疗科主任，上海市领军人才。擅长胸部肿瘤放疗和综合治疗，主要从事放疗的新技术、时间剂量分割、化疗、分子靶向药物、免疫治疗以及手术等综合性治疗，以及人工智能、图像识别和功能性影像应用和数据驱动下放疗策略及技术的个体化应用。',
        },
    ],
    [
        {
            title: '王延峰',
            subTitle: '上海交通大学 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/王延峰-83081e9bb219e702b5eb64732a1c1c33.png',
            mask: '教授，博士生导师，现任上海交通大学人工智能研究院副院长兼苏州人工智能研究院院长、未来媒体网络协同创新中心副主任。国家发改委人工智能专家委员会委员、科技部科技创新2030“新一代人工智能”重大项目指南专家组成员。',
        },
    ],
    [
        {
            title: '林浩添',
            subTitle: '中山大学中山眼科中心 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/林浩添-16a63e73be3ece0e606d68f063ba86c3.png',
            mask: '中山大学中山眼科中心副主任（副院长），医学人工智能与大数据学科带头人，研究员，主任医师，教授，眼科学和生物医学工程双学科博导。国家“万人计划”领军人才，中国青年五四奖章获得者，全国青联常委，国家重点研发计划项目首席科学家',
        },
    ],
    [
        {
            title: '高欣',
            subTitle: '上海全景医学影像科技股份有限公司 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/高欣-e25af96f988c50d5432f2762df3312d5.png',
            mask: '影像医学与核医学博士，副主任医师，全景医疗集团总部科研管理中心总监，上海大学附属全景医学影像诊断中心医疗副院长兼PET/MR主任，全国非公医疗机构协会核医学与分子影像专业委员会委员',
        },
    ],
    [
        {
            title: '李学龙',
            subTitle: '西北工业大学 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/cf69872cab7246e9fcd499fdc43e84aa-cf69872cab7246e9fcd499fdc43e84aa.png',
            mask: '教授，博导，西北工业大学校党委委员、校学术委员会副主任委员，校务委员会委员、国家杰出青年科学基金获得者，国家特聘专家入选者，国家重点研发计划首席科学家、中央军委科技委首批国防科技创新特区领域专家',
        },
    ],
    [
        {
            title: '梁佩鹏',
            subTitle: '首都师范大学 教授',
            src: 'https://static.wecity.qq.com/h5/2021-8/梁佩鹏-3a76162e391d2f4490e543850e589478.png',
            mask: '首都师范大学心理学院教授、博士生导师，美国卡内基梅隆大学心理系、香港中文大学（威尔斯亲王医院）访问学者。首都师范大学心理学院科研副院长，学习与认知北京市重点实验室副主任，北京市“磁共振脑成像”青年拔尖团队负责人',
        },
    ],
    [
        {
            title: '郑冶枫',
            subTitle: '腾讯医疗健康 首席科学家',
            src: 'https://static.wecity.qq.com/h5/2021-8/郑冶枫-f73bde395b84b89a5095fa26b83e7957.png',
            mask: '腾讯医疗健康首席科学家、美国医学和生物工程学会会士，共计发表论文150篇，被引用6000多次，h-index指数42，拥有美国发明专利70项，撰写专著2部，参编专著1部',
        },
    ],
    [
        {
            title: '韩骁',
            subTitle: '腾讯AI Lab 专家研究员',
            src: 'https://static.wecity.qq.com/h5/2021-8/韩骁-af3054209edc0a2f79c7743bfa9e17eb.png',
            mask: '美国约翰霍普金斯大学（The Johns Hopkins University）电子与计算机工程系硕士和博士、哈佛医学院博士后。主要研究方向为医学图像处理以及机器学习、深度学习在医疗领域的应用。在行业顶尖杂志和会议上发表论文60余篇，被引用5000多次，h-index指数达到30。获得已授权美国国家技术专利30多项以及中国国家专利10余项。',
        },
    ],
    [
        {
            title: '马锴',
            subTitle: '腾讯天衍实验室 专家研究员',
            src: 'https://static.wecity.qq.com/h5/2021-8/马锴-9515a12386f133c1551f4631f6d6b458.png',
            mask: '2013年于美国伊利诺伊大学获得博士学位，已在国际一流学术会议期刊上（如CVPR, ICCV, ECCV，MICCAI，IPMI，ICLR，AAAI，IJCAI，TMI，MedIA，TNNLS等）发表论文80余篇，获得美国批准发明专利20项，待批准13项，以及参与多个领域内核心期刊和会议的审稿工作。',
        },
    ],
    [
        {
            title: '钱天翼',
            subTitle: '腾讯医疗健康技术委员会 主任',
            src: 'https://static.wecity.qq.com/h5/2021-8/钱天翼-f34484cd0a1f20cfb09b1c33d3753bf7.png',
            mask: '腾讯觅影总经理，医疗影像国家新一代人工智能开放创新平台负责人，广东省医学影像人工智能开放创新平台负责人。在国内外知名学术期刊以及会议发表论文150余篇。同时担任多本国际学术期刊的审稿人',
        },
    ],
];
