
import { Options, Vue } from 'vue-class-component';
import { ElButton, ElForm, ElFormItem, ElInput, ElSelect, ElOption } from 'element-plus';
import { EbEnum } from '@/utils/constants';
import { enumToDescriptedArray } from '@/utils/enumToKv';
import { validateForm } from '@/utils/validate';

@Options({
    props: {
        childInfo: Object,
    },
    components: {
        Button: ElButton,
        Input: ElInput,
        Form: ElForm,
        FormItem: ElFormItem,
        Select: ElSelect,
        Option: ElOption,
    },
})
export default class StudentInfo extends Vue {
    childInfo!: Object;
    private ebEnum = enumToDescriptedArray(EbEnum);
    public formValue = {
        School: '',
        Major: '',
        Eb: '',
        Mentor: '',
    };
    private formInfo: InstanceType<typeof ElForm> | null = null;
    private rules: object = {
        School: [{ required: true, message: '请输入学校名称', trigger: ['blur', 'change'] }],
        Major: [{ required: true, message: '请输入专业', trigger: ['blur', 'change'] }],
        Eb: [{ required: true, message: '请选择学历', trigger: ['blur', 'change'] }],
        Mentor: [
            {
                validator: (rule: object, value: string, callback: Function) => {
                    if (['3', '4'].includes(this.formValue?.Eb) && !value) {
                        return callback(new Error('请输入导师姓名'));
                    }
                    callback();
                },
                trigger: ['blur', 'change'],
            },
        ],
    };
    public validateFormChildren() {
        return validateForm(<InstanceType<typeof ElForm>>this.formInfo);
    }
    mounted(): void {
        this.formInfo = this.$refs?.studentInfo as InstanceType<typeof ElForm>;
        this.$watch('childInfo', (value: any) => {
            this.formValue = { ...this.formValue, ...value };
        });
    }
}
