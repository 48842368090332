
import { Options, Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { useRouter, Router } from 'vue-router';
import { channelMap } from '@/utils/channelMap';

export default class RaceChannel extends Vue {
    private store: Store<any> = useStore();
    private orderMap = channelMap;

    private router: Router = useRouter();
    get channelInfo(): Object {
        return this.store.state.channelInfo;
    }
    private clickChannel(id: String) {
        this.router.push(`./channelDetail?id=${id}`);
    }
    private clickDataGuide() {
        this.router.push('./dataGuide');
    }
}
