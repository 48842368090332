import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Option = _resolveComponent("Option")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    model: _ctx.formValue,
    "label-position": "left",
    rules: _ctx.rules,
    ref: "otherInfo",
    "label-width": "72px",
    class: "submit-apply-bd--form",
    "hide-required-asterisk": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormItem, {
        label: "毕业学校",
        prop: "School"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Input, {
            type: "text",
            maxlength: "30",
            modelValue: _ctx.formValue.School,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValue.School) = $event)),
            autocomplete: "off",
            clearable: true,
            placeholder: "请输入学校名称"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_FormItem, {
        label: "学历",
        prop: "Eb"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Select, {
            modelValue: _ctx.formValue.Eb,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formValue.Eb) = $event)),
            placeholder: "请选择学历"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ebEnum, (item) => {
                return (_openBlock(), _createBlock(_component_Option, {
                  label: item.value,
                  key: item.key,
                  value: item.key
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.value), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_FormItem, {
        label: "专业",
        prop: "Major"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Input, {
            type: "text",
            maxlength: "30",
            modelValue: _ctx.formValue.Major,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formValue.Major) = $event)),
            autocomplete: "off",
            clearable: true,
            placeholder: "请输入专业"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}