
import { Options, Vue } from 'vue-class-component';
import { ElForm, ElFormItem, ElInput, ElSelect, ElOption } from 'element-plus';
import { EbEnum } from '@/utils/constants';
import { enumToDescriptedArray } from '@/utils/enumToKv';
import { validateForm } from '@/utils/validate';

@Options({
    props: {
        childInfo: Object,
    },
    components: {
        Input: ElInput,
        Form: ElForm,
        FormItem: ElFormItem,
        Select: ElSelect,
        Option: ElOption,
    },
})
export default class OtherInfo extends Vue {
    childInfo!: Object;
    private ebEnum = enumToDescriptedArray(EbEnum);
    public formValue = {
        School: '',
        Major: '',
        Eb: '',
    };
    private formInfo: InstanceType<typeof ElForm> | null = null;
    private rules: object = {
        School: [{ required: true, message: '请输入学校名称', trigger: ['blur', 'change'] }],
        Major: [{ required: true, message: '请输入专业', trigger: ['blur', 'change'] }],
        Eb: [{ required: true, message: '请选择学历', trigger: ['blur', 'change'] }],
    };
    public validateFormChildren() {
        return validateForm(<InstanceType<typeof ElForm>>this.formInfo);
    }
    mounted(): void {
        this.formInfo = this.$refs?.otherInfo as InstanceType<typeof ElForm>;
        this.$watch(
            'childInfo',
            (value: any) => {
                this.formValue = { ...this.formValue, ...value };
            },
            { immediate: true }
        );
    }
}
