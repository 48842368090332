import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    model: _ctx.formValue,
    "label-position": "left",
    rules: _ctx.rules,
    ref: "jobInfo",
    "label-width": "72px",
    class: "submit-apply-bd--form",
    "hide-required-asterisk": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormItem, {
        label: "工作单位",
        prop: "Company"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Input, {
            type: "text",
            maxlength: "30",
            modelValue: _ctx.formValue.Company,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValue.Company) = $event)),
            autocomplete: "off",
            clearable: true,
            placeholder: "请输入工作单位名称"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_FormItem, {
        label: "职务",
        prop: "Position"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Input, {
            type: "text",
            maxlength: "30",
            modelValue: _ctx.formValue.Position,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formValue.Position) = $event)),
            autocomplete: "off",
            clearable: true,
            placeholder: "请输入职务"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_FormItem, {
        label: "行业领域",
        prop: "Industry"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Input, {
            type: "text",
            maxlength: "30",
            modelValue: _ctx.formValue.Industry,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formValue.Industry) = $event)),
            autocomplete: "off",
            clearable: true,
            placeholder: "请输入行业所属领域"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}