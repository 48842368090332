import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"min-width":"1600px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GrayHeader = _resolveComponent("GrayHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_sigup_dialog = _resolveComponent("sigup-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GrayHeader, { onHandleLogin: _ctx.handleLogin }, null, 8, ["onHandleLogin"]),
    _createVNode(_component_router_view),
    (!_ctx.hasLogin)
      ? (_openBlock(), _createBlock(_component_sigup_dialog, {
          key: 0,
          dialogShow: _ctx.dialogShow,
          showClose: true,
          onClose: _ctx.closeToIndex
        }, null, 8, ["dialogShow", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}