import { ElForm } from 'element-plus';

function plusXing(str: string, frontLen: number, endLen: number) {
    const len = str.length - frontLen - endLen;
    let xing = '';
    for (let i = 0; i < len; i++) {
        xing += '*';
    }
    return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
}
function subStringfe(str: string, frontLen: number, endLen: number) {
    return str.substring(0, frontLen) + str.substring(str.length - endLen);
}
// 返回表单验证结果
export function validateForm(form: InstanceType<typeof ElForm>) {
    return new Promise(res => {
        form.validate((error: boolean | undefined) => res(error));
    });
}
// 验证手机号码格式
export function validatePhone(rule: object, value: string | number) {
    const reg = /^1[3-9]\d{9}$/;
    if (!value) {
        return { error: '请输入手机号码' };
    }
    if (!reg.test(`${value}`) && value) {
        return { error: '请输入正确的手机号码' };
    }
    return { error: '' };
}
// 验证姓名
export function validateName(rule: object, value: string, relValue: string) {
    if (!value) {
        return { error: '请输入姓名' };
    }
    if (relValue && value === relValue) {
        return { error: '' };
    }
    if (relValue && plusXing(value, 0, 1) !== relValue) {
        return { error: '请输入腾讯云实名认证的姓名' };
    }
    return { error: '' };
}
// 验证身份证号码格式
export function validateIdNo(rule: object, value: string, relValue: string) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!value) {
        return { error: '请输入身份证号码' };
    }
    if (!reg.test(`${value}`) && value) {
        return { error: '请输入正确的身份证号码' };
    }
    if (relValue && subStringfe(value, 3, 3) !== subStringfe(relValue, 3, 3)) {
        return { error: '请输入腾讯云实名认证的身份证号码' };
    }
    return { error: '' };
}
// 验证邮箱
export function validateEMail(rule: object, value: string | number) {
    const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if (!value) {
        return { error: '请输入邮箱' };
    }
    if (!reg.test(`${value}`) && value) {
        return { error: '请输入正确的邮箱' };
    }
    return { error: '' };
}
