import { createStore } from 'vuex';
import { getArUser, getQueryArRace, getQueryArChannel, getArTeam } from '@/api/index';
import { orderBy, findIndex } from 'lodash';
import { raceTimeLine } from '@/utils/commom';
import { IRaceInfo, channelInfo } from '@/interface/raceInfo';
import { ITeamInfo } from '@/interface/userInfo';
import { channelMap } from '@/utils/channelMap';
import { aegis } from '@/utils/aegis';

const state = {
    loginStatus: {
        haslogin: false,
    },
    userStatus: {
        AuditState: 0,
        Uin: '',
        PostInfos: [],
    },
    raceInfo: {
        Id: 0,
        Info: '',
        BonusSum: 0,
        Title: '',
        SubTitle: '',
        DetailUrl: '',
        PreEnd: '',
        PreStart: '',
        RegEnd: '',
        RegStart: '',
        SemiEnd: '',
        SemiStart: '',
        FinalEnd: '',
        FinalStart: '',
        LifeCycle: '',
        CurTime: '',
        hasAdd: false,
        hasModify: false,
        sortTimes: [],
    },
    channelInfo: [],
    teamInfo: null,
};
const getters = {
    loginStatus: (state: any) => {
        return state.loginStatus;
    },
    userStatus: (state: any) => {
        return state.userStatus;
    },
    raceInfo: (state: any) => {
        return state.raceInfo;
    },
    channelInfo: (state: any) => {
        return state.channelInfo;
    },
    teamInfo: (state: any) => {
        return state.teamInfo;
    },
};

const actions = {
    setLoginStatus: ({ commit }: any) => {
        commit('SET_USER_STATUS', state.loginStatus);
    },
    setUserStatus: async ({ commit }: any) => {
        const res = await getArUser();
        if (!res) return;
        const { Code, Data } = res;
        if (Code) {
            commit('SET_USER_STATUS', { AuditState: 0 }); // 代表未报名
            commit('SET_LOGIN_STATUS', { haslogin: false });
            return;
        }
        commit('SET_LOGIN_STATUS', { haslogin: true });
        if (Data[0]) {
            commit('SET_USER_STATUS', Data[0]);
            if (aegis) {
                aegis.setConfig({
                    uin: Data[0]?.Uin,
                });
            }
        }
    },
    setLogout: ({ commit }: any) => {
        commit('SET_USER_STATUS', { AuditState: 0 });
        commit('SET_LOGIN_STATUS', { haslogin: false });
    },
    getRaceInfo: async ({ commit }: any) => {
        const res = await getQueryArRace();
        if (!res) return;
        const { Code, Data } = res;
        if (!Code && Data[0]) {
            let race = { hasAdd: false, hasModify: false };
            // const currentTime = moment('2021-10-9 13:0:0').format();
            const sortTimeList = raceTimeLine().map(v => ({ name: v, time: Data[0]?.[v] }));
            const sortTimes = orderBy(sortTimeList, ['time'], ['asc']);
            const currentIndex = findIndex(sortTimes, v => v.name === 'CurTime');
            if (currentIndex < 2) {
                race.hasAdd = true;
                race.hasModify = true;
            }
            if (currentIndex === 2) {
                race.hasAdd = true;
            }
            race = { ...Data[0], sortTimes, ...race };
            commit('SET_RACE_INFO', race);
        } else {
            commit('SET_RACE_INFO', null);
        }
    },
    getChannelInfo: async ({ commit }: any) => {
        const res = await getQueryArChannel();
        if (!res) return;
        const { Code, Data } = res;
        if (!Code && Data) {
            commit('SET_CHANNEL_INFO', Data);
        } else {
            commit('SET_CHANNEL_INFO', []);
        }
    },
    getTeamInfo: async ({ commit }: any) => {
        const res = await getArTeam();
        if (!res) return;
        const { Code, Data } = res;
        if (!Code && Data[0]) {
            commit('SET_TEAM_INFO', Data[0]);
        } else {
            commit('SET_TEAM_INFO', null);
        }
    },
};

const mutations = {
    SET_LOGIN_STATUS(state: any, loginStatus: object) {
        state.loginStatus = loginStatus;
    },
    SET_USER_STATUS(state: any, userStatus: object) {
        state.userStatus = userStatus;
    },
    SET_RACE_INFO(state: any, raceInfo: IRaceInfo) {
        state.raceInfo = raceInfo;
    },
    SET_CHANNEL_INFO(state: any, channelInfo: Array<channelInfo>) {
        state.channelInfo = channelInfo.sort((a: any, b: any) => {
            return a.Order - b.Order;
        });
        state.channelInfo.forEach((v: channelInfo, i: number) => {
            v.CharName = channelMap.get(v.Order) || channelMap.get(i + 1) || '';
        });
    },
    SET_TEAM_INFO(state: any, teamInfo: ITeamInfo) {
        state.teamInfo = { ...teamInfo };
    },
};
export default createStore({
    state,
    getters,
    mutations,
    actions,
});
