
import { Vue } from 'vue-class-component';
import { prizeInfo } from '@/interface/homeInfo';

export default class RacePrize extends Vue {
    // 奖品
    private prizeItem: Array<prizeInfo> = [
        {
            title: '冠军队伍',
            subTitle: '1个冠军队伍，奖金5万人民币，颁发获奖证书',
            src: 'https://static.wecity.qq.com/miying/prize1-80188c0d51310c3f4639d64d84485b75.png',
        },
        {
            title: '亚军队伍',
            subTitle: '1个亚军队伍，奖金3万人民币，颁发获奖证书',
            src: 'https://static.wecity.qq.com/miying/prize2-08abeeb7cda5b12fa374c2e36d85d76e.png',
        },
        {
            title: '季军队伍',
            subTitle: '1个季军队伍，奖金1万人民币，颁发获奖证书',
            src: 'https://static.wecity.qq.com/miying/prize3-8363551cc76fa40e1975d6f360ba6b06.png',
        },
    ];
}
