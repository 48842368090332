
import { Vue } from 'vue-class-component';

export default class RaceRules extends Vue {
    private activeTab: Number = 0;
    private tabItem: Array<String> = ['参赛规则'];

    // 赛制
    private contestants: Object = {
        title: '参赛对象',
        subTitle: '大赛面向全社会开放，个人、高等院校、科研单位、企业等人员均可报名参赛，组队上限5人。',
        tips: '备注：腾讯集团员工也可报名参赛，但不得领取奖金，进入复赛的团队可获得相应的比赛纪念品，队伍内有一位腾讯系员工，即属于内部参赛团队；大赛主办单位、出题单位以及技术支持单位等能够接触赛题背景业务、产品、数据的员工不得参加此次比赛。',
    };

    private ruleItem: Array<Object> = [
        {
            title: '报名组队与实名认证',
            text: [
                '1. 报名方式：注册腾讯云账号并完成实名认证，登录比赛官网/觅影开放实验平台，完成注册，即可报名参赛。',
                '2. 选手可以单人参赛，也可以组队参赛。组队参赛的每个团队2-5人，每位选手只能加入一支队伍。',
                // '3. 每个参赛团队最多选择1个赛题。',
                '3. 选手需确保报名信息准确有效，组委会有权取消不符合条件队伍的参赛资格及奖励。',
                '4. 选手报名操作截止时间为10月10日中午12点。',
            ],
        },
        {
            title: '其他要求',
            text: [
                '1. 只允许单一算法模型/网络框架，不得使用多个模型投票的方式构建最终模型。',
                '2. 不得直接使用开源代码。',
                '3. 不同团队之间允许相互交流，但是不得直接复用代码。',
                '4. 不得使用人工标注的形式用于模型推断。',
            ],
        },
    ];
    clickTab(index: Number) {
        this.activeTab = index;
    }
}
