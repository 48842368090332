import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import home from '../views/home.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: home,
    },
    {
        path: '/personalInfo',
        name: 'PersonalInfo',
        component: () => import('@/views/personalInfo.vue'),
    },
    {
        path: '/personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/personalCenter.vue'),
    },
    {
        path: '/questions',
        name: 'Questions',
        component: () => import('@/views/questions.vue'),
    },
    {
        path: '/channelDetail',
        name: 'ChannelDetail',
        component: () => import('@/views/channelDetail.vue'),
    },
    {
        path: '/flowDetail',
        name: 'FlowDetail',
        component: () => import('@/views/flowDetail.vue'),
    },
    {
        path: '/faq',
        name: 'Faq',
        component: () => import('@/views/faq.vue'),
    },
    {
        path: '/question',
        name: 'Question',
        component: () => import('@/views/question.vue'),
    },
    {
        path: '/rankDetail',
        name: 'RankDetail',
        component: () => import('@/views/rankDetail.vue'),
    },
    {
        path: '/dataGuide',
        name: 'DataGuide',
        component: () => import('@/views/dataGuide.vue'),
    },
    {
        path: '/userGuide',
        name: 'UserGuide',
        component: () => import('@/views/userGuide.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach(async (to, from, next) => {
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    if (from.path === '/' && from.name !== 'Home') {
        store.dispatch('setUserStatus');
        store.dispatch('getRaceInfo');
        store.dispatch('getChannelInfo');
    }
    if (from.path !== '/' && !store.state.loginStatus?.haslogin) {
        // 清除用户信息 设置未登陆状态
        if (['PersonalInfo', 'PersonalCenter'].includes(<string>to?.name)) {
            next({
                path: '/',
            });
        }
    }
    next();
});

export default router;
