import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@assets/less/common.less';
import '@assets/less/common/common__scrollbar.less';
import 'element-plus/lib/theme-chalk/index.css';
import 'swiper/css/swiper.min.css';

createApp(App).use(store).use(router).mount('#app');
