
import { Options, Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';

@Options({
    props: ['dialogShow', 'showClose'],
})
export default class EndSignupDialog extends Vue {
    handleClose() {
        this.$emit('close', false);
    }

    login() {
        window.location.href = `https://console.cloud.tencent.com/taop/login?redirectUrl=${encodeURIComponent(window.location.href)}`;
    }
}
