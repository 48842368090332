export const channelMap = new Map([
    [1, '赛道一'],
    [2, '赛道二'],
    [3, '赛道三'],
    [4, '赛道四'],
    [5, '赛道五'],
]);
export const stepMap = new Map([
    [1, '初赛'],
    [2, '复赛'],
    [3, '决赛'],
]);
export const worksMap = new Map([
    [1, '标注'],
    [2, '作品'],
    [3, '审核文件'],
]);

export const workStatusMap = new Map([
    [0, '处理中'],
    [1, '处理中'],
    [2, '处理中'],
    [3, '成功'],
    [4, '失败'],
]);
